/* custom.scss: Loads after bootstrap / variables.scss overrides */
body {
  background-color: #fff; }

.meme {
  position: relative;
  padding-bottom: 50px;
  padding-top: 65px;
  background: linear-gradient(270deg, #ab00c6, #d0475e, #793d9b);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite; }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 97% 0%; }
  50% {
    background-position: 4% 100%; }
  100% {
    background-position: 97% 0%; } }

@keyframes AnimationName {
  0% {
    background-position: 97% 0%; }
  50% {
    background-position: 4% 100%; }
  100% {
    background-position: 97% 0%; } }
  .meme .pattern {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("/images/dee-pattern.png");
    background-size: 100px 100px;
    opacity: 0.1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    filter: alpha(opacity=10); }
  .meme span {
    display: block;
    color: #ffffff; }
  .meme span.line-1 {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -1px; }
  .meme span.line-2 {
    line-height: 60px;
    font-weight: 900;
    font-size: 70px;
    text-transform: uppercase; }
  .meme span.line-3 {
    font-size: 20px;
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70); }
  .meme .btn span {
    display: inline-block;
    color: inherit;
    font-weight: 900; }

.guide {
  padding-top: 75px;
  padding-bottom: 75px; }
  .guide h2 {
    font-weight: 900; }
  .guide .card .card-title {
    font-weight: 900; }

.footer {
  background-color: #212121; }
  .footer .copyright {
    display: block;
    color: #ffffff;
    font-size: 0.8rem; }
  .footer .legal-links a {
    color: #5E6197;
    font-size: 0.8rem; }
