@font-face {
  /* Open Sans Light */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/OpenSans/Light/OpenSans-Light.eot");
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/OpenSans/Light/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/Light/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans/Light/OpenSans-Light.ttf") format("truetype"); }

@font-face {
  /* Open Sans Light Italic */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot");
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("../fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/LightItalic/OpenSans-LightItalic.woff") format("woff"), url("../fonts/OpenSans/LightItalic/OpenSans-LightItalic.ttf") format("truetype"); }

@font-face {
  /* Open Sans Regular */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/OpenSans/Regular/OpenSans-Regular.eot");
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/OpenSans/Regular/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/Regular/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans/Regular/OpenSans-Regular.ttf") format("truetype"); }

@font-face {
  /* Open Sans Italic */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/OpenSans/Italic/OpenSans-Italic.eot");
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/OpenSans/Italic/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/Italic/OpenSans-Italic.woff") format("woff"), url("../fonts/OpenSans/Italic/OpenSans-Italic.ttf") format("truetype"); }

@font-face {
  /* Open Sans Bold */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/OpenSans/Bold/OpenSans-Bold.eot");
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/OpenSans/Bold/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/Bold/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans/Bold/OpenSans-Bold.ttf") format("truetype"); }

@font-face {
  /* Open Sans Bold Italic */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.eot");
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.woff") format("woff"), url("../fonts/OpenSans/BoldItalic/OpenSans-BoldItalic.ttf") format("truetype"); }
